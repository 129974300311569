import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';

const Banner = ({ numOrbs = 30 }) => {
  // Generate random properties for each orb
  const orbs = React.useMemo(() => {
    return Array.from({ length: numOrbs }).map(() => {
      const startTop = Math.random() * 100; // percentage
      const startLeft = Math.random() * 100;
      const duration = 3 + Math.random() * 4; // between 3s and 7s
      const delay = Math.random() * -duration; // negative delay for random start phase
      const color = Math.random() < 0.5 ? '#FFB300' : '#4FC3F7';
      return { startTop, startLeft, duration, delay, color };
    });
  }, [numOrbs]);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '500px',
        backgroundColor: 'black',
        overflow: 'hidden',
      }}
    >
      {/* Define keyframes for the diagonal shooting animation */}
      <style>
        {`
          @keyframes shoot {
            0% {
              opacity: 0;
              transform: translate(0, 0);
            }
            10% {
              opacity: 1;
            }
            100% {
              opacity: 0;
              transform: translate(100px, 100px);
            }
          }
        `}
      </style>

      {/* Render the orbs */}
      {orbs.map((orb, index) => (
        <Box
          key={index}
          sx={{
            position: 'absolute',
            top: `${orb.startTop}%`,
            left: `${orb.startLeft}%`,
            width: '5px',
            height: '5px',
            borderRadius: '50%',
            backgroundColor: orb.color,
            boxShadow: `0 0 8px ${orb.color}`,
            animation: `shoot ${orb.duration}s linear infinite`,
            animationDelay: `${orb.delay}s`,
          }}
        />
      ))}

      {/* Content overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
          textAlign: 'center',
          px: 4 ,
          zIndex: 1,
          width: '100%',
          maxWidth: '600px',
        }}
      >
        <Typography variant="h3" component="h1" gutterBottom>
          Let's <s>Elevate Your Brand</s> Make $$$, Together.
        </Typography>
        <Typography variant="body1">
          Lightweight Agency empowers your brand with pro-level online management and innovative digital services starting at $100/month, transforming your online presence into a growing empire. Our agile, sales-driven approach ensures you capture every opportunity to stand out, engage your audience, and outperform the competition.
        </Typography>
        <Button
            href="https://t.me/lightweight_agency" target="_blank" rel="noopener"
            variant="outlined"
            width="350px"
            sx={{ mt: 2 }}
          >
            Let's Talk On Telegram <TelegramIcon sx={{ color: '#FFB300', fontSize: '1.5rem' }} />
        </Button>
      </Box>
    </Box>
  );
};

export default Banner;
