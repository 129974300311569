import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './App.css'; // Import the CSS file

const FinalRecovery = () => {
	const [expandedCard, setExpandedCard] = useState(null);

	const cards = [
		{ id: 1, title: 'Market Research', description: 'We analyze market trends and customer behavior.', info: 'We analyze market trends and customer behavior to identify industry patterns and consumer preferences. This helps us develop data-driven strategies that effectively target your ideal audience and keep you ahead of the competition.' },
		{ id: 2, title: 'Strategic Planning', description: 'We develop a customized marketing strategy.', info: 'Our tailor-made marketing plans for your business means our strategies are designed specifically to help you reach your goals. Let us help you get the best results possible.' },
		{ id: 3, title: 'Deploy, Execute, & Measure', description: 'We implement the plan and track progress.', info: "We put the plan into action and monitor how well it's working. This way, we can make sure everything is on track and make adjustments if needed." },
		{ id: 4, title: 'Optimization & Refinement', description: 'We refine tactics to maximize results.', info: 'We tweak our strategies to make sure you get the best possible results. This way, we can constantly improve your campaigns for better performance.' },
	];

	const handleCardClick = (cardId) => {
		setExpandedCard(prev => (prev === cardId ? null : cardId));
	};

	return (
		<Box
			className='blue-gradient-blue-gradient'
			sx={{
				minHeight: '400px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				color: '#ffffff',
				fontFamily: 'monospace',
				overflow: 'hidden',
				padding: '0px 40px 0px 40px',
			}}
		>
			<Box
				sx={{
					width: '100%',
					textAlign: 'left',
				}}
			>
				<Typography variant="h5" sx={{
					padding: '40px 40px 0px 40px',
					fontSize: { xs: '1.75rem', sm: '2.25rem', lg: '3rem' },
					color: '#000000',
					marginTop: '80px',
					marginBottom: '40px',
					textAlign: 'center',
				}}>
					Strategy To Get Ahead.
				</Typography>
				<Typography variant="h5" sx={{ padding: { xs: '10px', sm: '10px', md: '20px', lg: '10px 40px' }, fontSize: '1.0rem', color: '#000000', textAlign: 'left' }}>
					&#9;Experience a boost in your marketing performance with lightweight’s boutique approach. By assembling small focus groups, conducting comprehensive competitive research, performing psychographic analysis, analyzing demographic data, plus A/B testing on your emails/social posts/website, we can uncover niche markets that offer substantial growth opportunities.
				</Typography>	
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'center',
					gap: '20px',
					marginTop: '40px',
					marginBottom: '80px',
				}}
			>
				{cards.map((card) => (
					<Box
						key={card.id}
						className={`card ${expandedCard === card.id ? 'expanded' : ''}`}
						sx={{
							width: '40%',
							border: '2px solid #FFB300',
							borderRadius: '8px',
							padding: '20px',
							backgroundColor: '#ffffff',
							color: '#000000',
							position: 'relative',
							maxHeight: 'initial!important',
							marginBottom: '20px',
							'@media (max-width: 600px)': {
								width: '100%',
								padding: '8px',
							},
						}}
						onClick={() => handleCardClick(card.id)}
					>
						<ExpandMoreIcon sx={{ color: '#FFB300', position: 'absolute', top: '10px', left: '10px' }} />
						<Typography variant="h6" sx={{
							fontWeight: 'bold',
							marginBottom: '10px',
							'@media (max-width: 600px)': {
								marginBottom: '30px',
								fontSize: '1.0rem',
							},
						}}>
							{card.title}
						</Typography>
						<Typography>{card.description}</Typography>
						{expandedCard === card.id && (
							<Typography variant="body1" sx={{
								marginTop: '20px',
								fontSize: '1.0rem',
							}}>
								{card.info}
							</Typography>
						)}
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default FinalRecovery;
